import React, { Component } from "react";

import Banner from "../utils/Banner";
import "./Rules.css";

export default class Rules extends Component {
  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">
            Regeln zur Nutzung des flips Gutscheinbuches
          </h1>
        </Banner>

        <div
          className="container content-container-bottom"
          style={{ marginTop: 40 }}
        >
          <table className="rules list-circle col-lg-8 col-md-10 col-sm-11 col-xs-12">
            <tbody>
              <tr>
                <td>
                  <Circle value={1} />
                </td>
                <td>
                  Das flips Gutscheinbuch hat Gültigkeit vom 01.01. bis
                  31.12.2025. Beachten Sie bitte den Hinweis zur Geltungsdauer
                  der Gastronomiegutscheine in Regel 6 sowie etwaige Angaben zur
                  Gültigkeit auf den einzelnen Gutscheinen.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={2} />{" "}
                </td>
                <td>
                  <b>
                    Bitte geben Sie vor Bestellung oder Kauf an, dass Sie ein
                    flips Angebot nutzen möchten.
                  </b>{" "}
                  So können eventuelle Abrechnungsprobleme vermieden werden.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={3} />
                </td>
                <td>
                  Die Kombination eines flips Gutscheines mit weiteren
                  Preisnachlässen ist nicht möglich.{" "}
                  <b>
                    Es kann pro Person nur ein Gutschein je Besuch/Tag in
                    Anspruch genommen werden.
                  </b>{" "}
                  Gegenseitiges „Einladen“ ist nicht möglich.
                </td>
              </tr>
              <tr style={{ verticalAlign: "middle" }}>
                <td>
                  {" "}
                  <Circle value={4} />
                </td>
                <td>
                  <b>Trennen Sie die Gutscheine nicht selber ab,</b> da sie dann
                  nicht mehr gültig sind.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={5} />
                </td>
                <td>
                  Alle Angebote dieses Buches gelten nicht für bereits im Preis
                  ermäßigte Waren (z.B. während der Happy Hour) und
                  Dienstleistungen sowie bei individuell ausgehandelten Preisen.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={6} />
                </td>
                <td>
                  Die Gastronomiegutscheine sind an Feiertagen, am Valentins-
                  und am Muttertag sowie in der Zeit vom 24.12. bis 31.12. nicht
                  gültig. Sie beziehen sich grundsätzlich auf die
                  Standardspeisekarte und den Verzehr im Restaurant. Saison- und
                  andere Spezialkarten sind vom Angebot ebenso ausgenommen wie
                  Bringdienst und Außer-Haus-Verkauf.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={7} />
                </td>
                <td>
                  Die Gutscheine gelten nur für das konkret beschriebene
                  Angebot. Sie sind nicht auf andere Waren oder Dienstleistungen
                  übertragbar. Eine Barauszahlung (auch von Differenzbeträgen)
                  ist ausgeschlossen.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={8} />
                </td>
                <td>
                  Die Formulierung „der/die/das günstigere ist kostenfrei"
                  umfasst auch preisgleiche Bestellungen.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={9} />
                </td>
                <td>
                  Die 2 für 1 Eintritts-Gutscheine können grundsätzlich für zwei
                  gleichwertige oder für eine reguläre und eine ermäßigte
                  Eintrittskarte eingelöst werden. Ausnahmen hiervon sind auf
                  den Gutscheinen vermerkt. Bei Auswahl zweier Eintrittskarten
                  mit unterschiedlichem Preis ist stets die günstigere
                  kostenfrei.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={10} />
                </td>
                <td>
                  Das flips Gutscheinbuch ist personenbezogen und nicht
                  übertragbar. Es wird nur einmal jährlich pro Person anerkannt.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={11} />
                </td>
                <td>
                  Bitte verstehen Sie die Angebote im flips Gutscheinbuch als
                  Entgegenkommen der teilnehmenden Unternehmen. Alle Inserenten
                  freuen sich auf Ihren Besuch. Sollte trotzdem etwas nicht wie
                  gewünscht klappen, bitten wir um Ihr Verständnis und Ihre
                  Nachsicht.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Circle value={12} />
                </td>
                <td>
                  Durch Inhaberwechsel oder Schließung können einzelne
                  Gutscheine ungültig werden. Für Irrtümer, Druckfehler und
                  Änderungen übernehmen wir keine Gewähr. Alle Angaben Stand
                  01.12.2024.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const Circle = (props) => (
  <div
    style={{
      width: 50,
      height: 50,
      backgroundColor: props.color || "#475269",
      marginRight: 20,
      marginTop: 4,
      borderRadius: "50%",
      textAlign: "center",
      padding: 13,
      color: "#fff",
      fontWeight: 500,
    }}
  >
    {props.value}
  </div>
);
