import React, { Component } from "react";

// import Map from '../utils/Map'
import Banner from "../utils/Banner";
import { Link } from "react-router-dom";

import soldoutBadge from "../images/soldout1.png";
import reservationBookImage from "../images/flips_slider_2018_with_book1.png";

//import bookImage from "../images/flips_reservieren_Grafik_Web.png";
import bookImage from "../images/flips_book_2019_1.png";

var braunschweig = [
  {
    name: "Buchhandlung Graff",
    line1: "Sack 15",
    line2: "38100 Braunschweig",
    phone: "0531 4808944",
    soldout: false,
    coords: { lat: 52.265107, lng: 10.521717 },
  },
  {
    name: "Thalia Buchhandlung",
    line1: "Schloss-Arkaden",
    line2: "38100 Braunschweig",
    phone: "0531 1294960",
    soldout: false,
    coords: { lat: 52.264053, lng: 10.529368 },
  },
  {
    name: "Buchhandlung Pfankuch",
    line1: "Vor der Burg 1",
    line2: "38100 Braunschweig",
    phone: "0531 45303",
    soldout: false,
    coords: { lat: 52.263465, lng: 10.522063 },
  },
  {
    name: "Touristinfo Braunschweig",
    line1: "Kleine Burg 14",
    line2: "38100 Braunschweig",
    phone: "0531 4702040",
    soldout: false,
    coords: { lat: 52.263796, lng: 10.522343 },
  },

  // {
  //   name: "Konzertkasse",
  //   line1: "Schloss-Arkaden",
  //   line2: "38100 Braunschweig",
  //   phone: "0531 16606",
  //   soldout: false,
  //   coords: { lat: 52.264355, lng: 10.52881 },
  // },

  // {
  //   name: "Galeria Kaufhof",
  //   line1: "Bohlweg 72",
  //   line2: "38100 Braunschweig",
  //   phone: "0531 47140",
  //   soldout: false,
  //   coords: { lat: 52.262125, lng: 10.526473 }
  // }
];

var peine = [
  {
    name: "Thalia Peine",
    line1: "Breite Straße 8",
    line2: "31224 Peine",
    phone: "05171 505790",
    soldout: false,
    coords: { lat: 52.322602, lng: 10.226427 },
  },
  // {
  //   name: "Service-Center Peiner Nachrichten",
  //   line1: "Gröpern 21",
  //   line2: "31224 Peine",
  //   phone: "0531 16606",
  //   soldout: false,
  //   coords: { lat: 52.320893, lng: 10.229482 },
  // },
];

var wolf = [
  {
    name: "Tourist-Information",
    line1: "Stadtmarkt 7 A",
    line2: "38300 Wolfenbüttel",
    phone: "05331 86280",
    soldout: false,
    coords: { lat: 52.162448, lng: 10.534483 },
  },
  {
    name: "Buchhandlung Steuber",
    line1: "Am Alten Tore 5",
    line2: "38300 Wolfenbüttel",
    phone: "05331 92080",
    soldout: false,
    coords: { lat: 52.163823, lng: 10.536349 },
  },
];

var wolfsburg = [
  // {
  //   name: "Thalia Wolfsburg",
  //   line0: "City-Galerie Wolfsburg",
  //   line1: "Porschestraße 45",
  //   line2: "38440 Wolfsburg",
  //   phone: "05361 1803",
  //   soldout: false,
  //   coords: { lat: 52.162448, lng: 10.534483 },
  // },
  // {
  //   name: "Service-Center Wolfsburger Nachrichten",
  //   line1: "Porschestraße 22-24",
  //   line2: "38440 Wolfsburg",
  //   phone: "0531 16606",
  //   soldout: false,
  //   coords: { lat: 52.163823, lng: 10.536349 },
  // },
];

//
// function addressesInfo(addresses) {
//   return addresses.map ( (c,index) => (
//       {coords:c.coords, info: (<div><b>{c.name}</b><br/>{c.line1}</div>) }
//   ))
// }

export default class Sellpoints extends Component {
  render() {
    // var braunschweig_sort = [...braunschweig]
    // braunschweig_sort[2]=braunschweig[4]
    // braunschweig_sort[3]=braunschweig[5]
    // braunschweig_sort[4]=braunschweig[2]
    // braunschweig_sort[5]=braunschweig[3]

    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">Verkaufsstellen</h1>
          <div className="row">
            <div className="col-lg-9 col-md-10 col-sm-12 col-xs-12">
              <p
                className="flips-banner-text"
                style={{ marginTop: 2, marginBottom: 0 }}
              >
                {/* Das flips Gutscheinbuch 2017 ist bei folgenden Verkaufsstellen
                zum Preis von 18,95 Euro erhältlich: */}
                {/* Die flips Ausgabe 2018 ist ab 1. Dezember bei folgenden
                Verkaufsstellen zum Preis von 18,95 Euro erhältlich: */}
                {/* Die flips Ausgabe 2018 ist bei folgenden Verkaufsstellen zum
                Preis von&nbsp;18,95&nbsp;Euro erhältlich: */}
                {/* Die flips Ausgabe 2019 ist bei den folgenden Verkaufsstellen zum
                Preis von&nbsp;18,95&nbsp;Euro erhältlich: */}
                {/* Die flips Ausgabe 2020 ist ab dem 30. November bei folgenden
                Verkaufsstellen zum Preis von&nbsp;18,95&nbsp;€ erhältlich: */}
                {/* Die flips Ausgabe 2020 ist bei den folgenden Verkaufsstellen zum
                Preis von&nbsp;18,95&nbsp;Euro erhältlich: */}
                {/* Die flips Ausgabe 2021 ist ab dem 1. Dezember bei folgenden
                Verkaufsstellen zum&nbsp;Preis von&nbsp;18,95&nbsp;€ erhältlich:  */}
                {/* Die flips Ausgabe 2022 ist ab dem 01.12.21 bei den folgenden
                Verkaufsstellen zum Preis von 15,00 Euro erhältlich: */}
                {/* Die flips Ausgabe 2022 ist bei den folgenden Verkaufsstellen zum Preis von 15,00 Euro erhältlich: */}
                {/* Die flips Ausgabe 2024 ist ab dem 01.12.2023 bei den folgenden
                Verkaufsstellen zum Preis von 18,95 Euro erhältlich: */}
                {/* Die flips Ausgabe 2025 ist bei den folgenden Verkaufsstellen zum
                Preis von 18,95&nbsp;Euro erhältlich: */}
                {/* Die flips Ausgabe 2025 ist ab dem 01.12.2024 bei den folgenden
                Verkaufsstellen zum Preis von 18,95 Euro erhältlich: */}
                Die flips Ausgabe 2025 ist bei den folgenden Verkaufsstellen zum
                Preis von 18,95&nbsp;Euro erhältlich:
              </p>
            </div>
          </div>
        </Banner>

        <div className="container content-container" style={{ paddingTop: 30 }}>
          {/* <h3 className="flips-header">BRAUNSCHWEIG</h3> */}
          <City
            addresses={braunschweig}
            center={{ lat: 52.264344, lng: 10.526146 }}
          />

          {/* <div className="hidden-xs hidden-sm">
          <City  addresses={braunschweig_sort} center={{ lat: 52.264344, lng: 10.526146 }} />
        </div>
        <div className="hidden-lg hidden-md">
        <City className="" addresses={braunschweig} center={{ lat: 52.264344, lng: 10.526146 }} />
        </div> */}

          <h3 className="flips-header">WOLFENBÜTTEL</h3>
          <City addresses={wolf} center={{ lat: 52.163823, lng: 10.536349 }} />

          {/* <h3 className="flips-header">WOLFSBURG</h3>
          <City
            addresses={wolfsburg}
            center={{ lat: 52.321893, lng: 10.229482 }}
          /> */}

          <h3 className="flips-header">PEINE</h3>
          <City addresses={peine} center={{ lat: 52.321893, lng: 10.229482 }} />
        </div>
      </div>
    );
  }
}

class City extends Component {
  render() {
    return (
      <div>
        {/* <Map  markers={addressesInfo(this.props.addresses)} mapHeight={250}
            zoom={15}
            center={this.props.center}
            options={{   scrollwheel: false }}/> */}

        <div className="row" style={{ marginTop: 20 }}>
          {this.props.addresses.map((c, index) => (
            <div key={index} className="col-md-3 col-sm-4 col-xs-12">
              <Address address={c} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

class Address extends Component {
  render() {
    return (
      <address>
        {this.props.address.soldout ? (
          <img
            src={soldoutBadge}
            alt=""
            style={{
              width: 120,
              position: "absolute",
              zIndex: 100,
              left: 110,
              top: 10,
            }}
          />
        ) : (
          ""
        )}
        <strong>{this.props.address.name}</strong>
        {this.props.address.line0 && (
          <span>
            <br />
            {this.props.address.line0}
          </span>
        )}
        <br />
        {this.props.address.line1}
        <br />
        {this.props.address.line2}
        <br />
        Tel.:{" "}
        <a href={"tel:" + this.props.address.phone.replace(" ", "")}>
          {this.props.address.phone}
        </a>
      </address>
    );
  }
}

export class SellpointsSoldout extends Component {
  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">Verkaufsstellen</h1>
        </Banner>

        <div
          className="container content-container"
          style={{ marginTop: 0, marginBottom: 200, fontSize: 18 }}
        >
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-4">
              <div
                style={{
                  position: "relative",
                }}
              >
                <img
                  style={{
                    marginRight: 0,
                    marginTop: 30,
                  }}
                  src={bookImage}
                  className="img-responsive hidden-xs"
                  role="presentation"
                />

                {/* <img
                  style={{
                    position: "absolute",
                    bottom: 40,
                    marginRight: 20,
                    marginTop: 30
                  }}
                  src={soldoutBadge}
                  className="img-responsive hidden-xs"
                  role="presentation"
                /> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-8">
              <h3 style={{ fontSize: 24, fontWeight: 600, marginTop: 50 }}>
                Die flips Ausgabe 2019 ist ausverkauft.
              </h3>
              <p style={{ marginTop: 18 }}>
                <Link to="/reservieren">Sichern</Link> Sie sich jetzt Ihr
                Gutscheinbuch für das Jahr 2020.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class SellpointsReservation extends Component {
  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">Verkaufsstellen</h1>
        </Banner>

        <div
          className="container content-container"
          style={{ marginTop: 40, marginBottom: 200, fontSize: 18 }}
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-5">
              <img
                style={{ marginRight: 20, marginTop: -20 }}
                src={reservationBookImage}
                className="img-responsive hidden-xs"
                role="presentation"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-7"
              style={{ marginTop: 20 }}
            >
              <p style={{ fontWeight: "bold", marginBottom: 20 }}>
                Die flips Ausgabe 2018 ist ab 1. Dezember erhältlich.
                Informationen zu den Verkaufsstellen veröffentlichen wir hier in
                Kürze.
              </p>
              <p>
                <Link to={"/reservierung"}>Reservieren</Link> Sie jetzt Ihr
                flips Gutscheinbuch 2018! Noch bis zum 30.11.2017 sparen Sie
                einen Euro pro Exemplar.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
