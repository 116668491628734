import React, { Component } from "react";
import { Link } from "react-router-dom";

import Banner from "../utils/Banner";

import googlePlayBadge from "./google-play-badge.svg";
import appStoreBadge from "./app-store-badge.svg";

//import appImage from "../images/flips_app2020.jpg";
import appImage from "../images/flips-website-app-trilogie.jpg";

import "./Apps.css";

export default class Apps extends Component {
  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header text-center">
            App für iOS und Android
          </h1>
        </Banner>

        <div
          className="container apps content-container"
          style={{ marginTop: 0, marginBottom: 60 }}
        >
          <AppDesc />
          <AppImage />

          <AppFeatures />
          <AppFooter />
        </div>
      </div>
    );
  }
}

function AppImage() {
  return (
    <div className="">
      <div className="device-container1">
        <img
          role="presentation"
          src={appImage}
          className="img-responsive"
          style={{
            maxHeight: 380,
            marginTop: 30,
            paddingRight: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </div>
    </div>
  );
}

function AppDesc() {
  return (
    <div className="">
      <p className="text-center" style={{ marginTop: 30 }}>
        Die praktische Was-Wann-Wo-App gibt es für 2025 in 2 Versionen. Die neue
        Version mit digitalen Gutscheinen ermöglicht das Einlösen aller im flips
        Gutscheinbuch veröffentlichten Gutscheine ohne Buch. Sie ersetzt das
        Buch. Bitte laden Sie die Standard App und aktivieren Sie die digitalen
        Gutscheine durch Eingabe eines Aktivierungscodes. <br />
        Diesen erhalten Sie im regionalen Buchhandel.
      </p>
    </div>
  );
}

function AppFeatures() {
  return (
    <div className="row apps">
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <h3 class="">Standard App</h3>
        <ul>
          <li>Kostenfrei</li>
          <li>
            Das flips Gutscheinbuch wird zum Einlösen von Gutscheinen benötigt
          </li>
          <li>Ist die ideale Ergänzung zum flips Gutscheinbuch</li>
          <li>Hilfreiche Such und Filter-Funktionen</li>
          <li>Standortbezogen Gutscheine finden</li>
          <li>Kartenansicht und Navigation</li>
          <li>Persönliche Merkliste</li>
          <li>Terminkalender</li>
        </ul>
      </div>

      <div className="col-md-1"></div>
      <div className="col-md-5">
        <h3 class="">Die digitalen Gutscheine</h3>
        <ul>
          <li>Kostenpflichtig 18,95 €</li>
          <li>
            Ermöglicht das Einlösen aller im flips Gutscheinbuch
            veröffentlichten Gutscheine ohne Buch
          </li>
          <li>Hat alle Funktionen der Standard App</li>
          <li>Die App kann auf bis zu drei Geräten verwendet werden</li>
          <li>Ab 01.01.2025 aktivierbar</li>
        </ul>
      </div>
    </div>
  );
}

function AppFooter() {
  return (
    <div className="text-center">
      <div style={{ marginTop: 40 }}>
        <Link to="/datenschutz-app">Datenschutzhinweise</Link>
      </div>

      <div className="badges">
        <a
          className="badge-link"
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.greenstones.flips"
        >
          <img src={googlePlayBadge} alt="" />
        </a>{" "}
        <a
          className="badge-link"
          target="_blank"
          href="https://itunes.apple.com/de/app/flips/id599955909?mt=8"
        >
          <img src={appStoreBadge} alt="" />
        </a>
      </div>

      <p style={{ marginTop: 30 }}>
        Über Feedback und Anregungen zur flips App freuen wir uns:{" "}
        <a href="mailto:service@flips.de">service@flips.de</a>.
      </p>

      <p>
        <strong style={{}}>Viel Spaß mit der flips App!</strong>
      </p>
    </div>
  );
}
