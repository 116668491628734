import React, { Component, useEffect, useMemo, useState } from "react";
import Lightbox from "react-images";
import { connect } from "react-redux";
import { useLocation } from "react-router";

import Banner from "../utils/Banner";
import { getImageURL } from "../utils/Image";
import "./Offers.css";

class Category extends Component {
  openLightbox(index, event) {
    event.preventDefault();
    this.props.openLightbox(index, event);
  }

  render() {
    var c = this.props.category;
    var locations = c.locations;

    return (
      <div>
        <div className="cat-header">
          <div className="cat-header-content">
            <h3>
              <b>{c.name}</b>
            </h3>
            {/* <span className="counter-value">{c.locCount}</span>{" "}
            <span className="counter-label">Anbieter</span> */}
            {/* {' '}
            <Link to={`angebote/${this.props.categoryIndex}`}>Alle anzeigen</Link> */}
          </div>
        </div>
        {locations.map((l, i) => (
          <div key={i}>
            <div>
              <a
                href="#"
                onClick={(e) => this.openLightbox(l.lightboxIndex, e)}
              >
                {l.name}
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

function OffersContent({
  categories,
  selectedIndex,
  selectedPage,
  imageFolder,
}) {
  const [lightbox, setLightbox] = useState({
    lightboxIsOpen: false,
    currentImage: 0,
  });

  const images = useMemo(() => {
    if (!categories || categories.length === 0) return null;

    var images = [];
    var index = 0;

    categories.forEach((c) => {
      c.locations = c.locations.sort((o1, o2) => {
        var v1 = o1.name;
        var v2 = o2.name;
        if (v1 > v2) return 1;
        if (v1 < v2) return -1;
        return 0;
      });

      c.locations.forEach((l) => {
        l.lightboxIndex = index;
        index++;
        images.push({
          src: getImageURL(imageFolder, l.logo),
          caption: c.name,
          page: l.page,
        });
      });
    });
    return images;
  }, [categories]);

  useEffect(() => {
    // if (selectedIndex) {
    //   setLightbox({
    //     currentImage: parseInt(selectedIndex),
    //     lightboxIsOpen: true,
    //   });
    // }

    if (images && selectedPage) {
      const found = images.findIndex((i) => i.page === selectedPage);
      if (found !== -1) {
        setLightbox({
          currentImage: found,
          lightboxIsOpen: true,
        });
      }
    }
  }, [images, selectedPage]);

  const openLightbox = (index, event) => {
    event.preventDefault();
    setLightbox({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };

  const closeLightbox = () => {
    setLightbox({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  const gotoPrevious = () => {
    setLightbox({
      currentImage: lightbox.currentImage - 1,
      lightboxIsOpen: true,
    });
  };

  const gotoNext = () => {
    setLightbox({
      currentImage: lightbox.currentImage + 1,
      lightboxIsOpen: true,
    });
  };

  const gotoImage = (index) => {
    setLightbox({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };

  if (!images) return <div></div>;

  return (
    <div className="row">
      <Lightbox
        images={images}
        currentImage={lightbox.currentImage}
        isOpen={lightbox.lightboxIsOpen}
        onClickImage={(e) => () => {}}
        onClickNext={(e) => gotoNext()}
        onClickPrev={(e) => gotoPrevious()}
        onClickThumbnail={(e) => gotoImage(e)}
        onClose={(e) => closeLightbox()}
        showThumbnails={false}
        backdropClosesModal={true}
        imageCountSeparator=" von "
      />

      {categories.map((c, i) => (
        <div className="col-lg-4 col-md-4 col-sm-6" key={i}>
          <Category
            category={c}
            categoryIndex={i}
            openLightbox={(i, e) => openLightbox(i, e)}
            imageFolder={imageFolder}
          />
        </div>
      ))}
    </div>
  );
}

class OffersContentOld extends Component {
  state = {
    lightboxIsOpen: false,
    currentImage: 0,
  };

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  gotoImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  componentDidMount() {
    var { selectedIndex, selectedPage } = this.props;

    if (selectedIndex) {
      this.setState({
        currentImage: parseInt(selectedIndex),
        lightboxIsOpen: true,
      });
    }
  }

  render() {
    var images = [];
    var index = 0;

    if (!this.props.categories || this.props.categories.length == 0)
      return <div />;

    this.props.categories.forEach((c) => {
      c.locations = c.locations.sort((o1, o2) => {
        var v1 = o1.name;
        var v2 = o2.name;
        if (v1 > v2) return 1;
        if (v1 < v2) return -1;
        return 0;
      });

      c.locations.forEach((l) => {
        l.lightboxIndex = index;
        index++;
        images.push({
          src: getImageURL(this.props.imageFolder, l.logo),
          caption: c.name,
          page: c.page,
        });
      });
    });

    //console.log(images);

    return (
      <div className="row">
        <Lightbox
          images={images}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={(e) => this.handleClickImage()}
          onClickNext={(e) => this.gotoNext()}
          onClickPrev={(e) => this.gotoPrevious()}
          onClickThumbnail={(e) => this.gotoImage(e)}
          onClose={(e) => this.closeLightbox()}
          showThumbnails={false}
          backdropClosesModal={true}
          imageCountSeparator=" von "
          //theme={this.props.theme}
        />

        {this.props.categories.map((c, i) => (
          <div className="col-lg-4 col-md-4 col-sm-6" key={i}>
            <Category
              category={c}
              categoryIndex={i}
              openLightbox={(i, e) => this.openLightbox(i, e)}
              imageFolder={this.props.imageFolder}
            />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("mapStateToProps", state);
  return {
    categories: state.offers,
  };
};
const OffersContentThisYear = connect(mapStateToProps)(OffersContent);

const OffersPreview = () => {
  const { state } = useLocation();
  var { p } = state || {};

  return (
    <div>
      <Banner>
        <h1 className="flips-banner-header">Angebote 2023</h1>
      </Banner>

      <div
        className="container content-container-bottom offers "
        style={{ marginTop: 10 }}
      >
        <OffersContentThisYear
          //   selectedIndex={li}
          selectedPage={p}
          imageFolder="preview2023"
        />
      </div>
    </div>
  );
};

const mapStateToPropsNY = (state) => {
  //console.log("mapStateToPropsNY", state);
  return {
    categories: state.nextYearOffers,
  };
};
const OffersContentNY = connect(mapStateToPropsNY)(OffersContent);

export const NextYearOffersPreview = () => {
  const { state } = useLocation();
  var { li, p } = state || {};

  return (
    <div>
      <Banner>
        <h1 className="flips-banner-header">Angebote 2025</h1>
      </Banner>

      <div
        className="container content-container-bottom offers "
        style={{ marginTop: 10 }}
      >
        <OffersContentNY
          //selectedIndex={li}
          selectedPage={p}
          imageFolder="preview2025"
        />
      </div>
    </div>
  );
};

export default OffersPreview;
