import React, { Component } from "react";
//import bookImage from "../images/flips_book_2019_simple.png";
//import bookImage from "../images/flips_2020_p.png";

import bookImage from "../images/flips_2025_order.png";
import Banner from "../utils/Banner";

let orderLink =
  "https://www.graff.de/shop/item/9925432000453/flips-gutscheinbuch-2025-kartoniertes-buch";

let appCardOrderLink =
  "https://www.graff.de/shop/item/9911320000698/flips-gutschein-app-2025";

export default class Order extends Component {
  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">Online bestellen</h1>
        </Banner>

        <div
          className="container content-container"
          style={{ marginTop: 20, marginBottom: 200, fontSize: 16 }}
        >
          <div className="row" style={{ fontSize: 18 }}>
            <div
              // className="col-lg-3 col-md-4 col-sm-5"
              className="col-lg-4 col-md-4 col-sm-5"
              style={{ paddingRight: 40 }}
            >
              <a href={orderLink} target="_blank" id="AO9">
                <img
                  style={{
                    marginLeft: 20,
                    marginTop: 10,
                    paddingRight: 20,
                    maxHeight: 360,
                  }}
                  src={bookImage}
                  className="img-responsive hidden-xs"
                  role="presentation"
                />
              </a>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-7"
              style={{ marginTop: 40 }}
            >
              <p>
                Die Ausgabe 2025 des flips Gutscheinbuches ist zum Preis von
                18,95 Euro erhältlich.
              </p>
              <p>
                Klicken Sie bitte für die Onlinebestellung des flips
                Gutscheinbuches auf den Link zur Internetseite der Buchhandlung
                Graff.
                {/* Das flips Gutscheinbuch 2020 kostet 18,95 Euro. <br />
                Sichern Sie sich jetzt Ihr Exemplar und bestellen Sie Ihr
                Gutscheinbuch! Auch gerne zum Verschenken an Familie, Freunde
                und Arbeitskollegen. Die bestellten Bücher liegen für Sie zur
                Abholung bei der Buchhandlung Graff bereit. Sie können sich
                diese auch nach Hause oder an eine Wunschadresse liefern lassen. */}
              </p>

              <p>
                <a
                  className="btn btn-info"
                  style={{ fontSize: 18, marginTop: 30 }}
                  href={orderLink}
                  target="_blank"
                  id="AO9"
                >
                  Buch bestellen (bei Buchhandlung Graff)
                </a>
              </p>

              <p>
                <a
                  className="btn btn-info"
                  style={{ fontSize: 18, marginTop: 10 }}
                  href={appCardOrderLink}
                  target="_blank"
                  id="AO9"
                >
                  App-Karte bestellen (bei Buchhandlung Graff)
                </a>
              </p>

              {/* <p style={{ marginTop: 20 }}>
                <a
                  href="https://www.graff.de/shop/add/VL702850FLIPS/flips%20Gutscheinbuch%202017/18%2C95/Hier%20k%c3%b6nnen%20Sie%20das%20flips%20Gutscheinbuch%20zur%20Abholung%20in%20der%20Buchhandlung%20Graff%20bestellen.%20Falls%20Sie%20Zusendung%20zuz%c3%bcgl.%201%20Euro%20Versandkosten%20w%c3%bcnschen%2c%20suchen%20Sie%20bitte%20nach%20Flips%20Gutscheinbuch%20mit%20der%20EAN%209921300000334..html"
                  target="_blank"
                  id="AO9"
                >
                  Weiterleitung zum Bestellformular der Buchhandlung Graff
                </a>
              </p> */}
              {/* 
              <p style={{ marginTop: 74 }}>
                Alternativ erhalten Sie das flips Gutscheinbuch bei den{" "}
                <Link to="/verkaufsstellen">Verkaufsstellen</Link> in
                Braunschweig, Wolfenbüttel und Wolfsburg.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
